import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import { Link } from 'gatsby';
const FormComponentLandingPageTwo = props => {
  const { freeAccountTitle, freeAccountButton, isSolutions } = props;
  const container = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      loop: true,
      autoplay: true,
      animationData: require('../../animation/lottieForm.json'),
    });
  }, []);
  return (
    <div className="full-container">
      <div className="form-landingPage-section">
        <div className="row">
          <div
            className={`col-lg-6 col-md-6 col-sm-12  ${
              isSolutions ? 'order-md-2' : ''
            }`}
          >
            <div className="lotti" ref={container}></div>
          </div>
          <div
            className={`col-lg-6 col-md-6 col-sm-12 form-landingPage-section-col ${
              isSolutions ? 'order-md-1' : ''
            }`}
          >
            <p
              className={`form-landingPage-section_subtitle ${
                isSolutions ? 'solution-title' : ''
              }`}
            >
              {freeAccountTitle}
            </p>
            <p
              className={`form-landingPage-section_subtitle text ${
                isSolutions ? 'solution-description' : ''
              }`}
            >
              Agreed’s proven system of action empowers your initiatives to
              succeed. Let’s chat!
            </p>
            <form>
              <div className="teamwork-section_form-wrapper landingpage-button">
                <Link to="/contact" className="help-section-link">
                  {freeAccountButton}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormComponentLandingPageTwo;
