import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
const FeatureComponentTwo = props => {
  const {
    featureTitle,
    featureBusinessDescription,
    featureWorkDescription,
    featureChangeDescription,
    FeatureImage,
  } = props;
  return (
    <div className="feature-section">
      <div className="full-container">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12 ">
            <GatsbyImage
              image={FeatureImage}
              alt={''}
              className="feature-section_image"
            />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 feature-section_image-col">
            <h3 className="feature-section_title leader-title">
              {featureTitle}
              <span className="feature-section_title-border leader-border"></span>
            </h3>
            <p className="feature-section_text">{featureWorkDescription}</p>
            <p className="feature-section_text">{featureChangeDescription}</p>
            <p className="feature-section_text">{featureBusinessDescription}</p>
            {/* <p className="feature-section_text">
              We codified a system of action that accelerates results by
              building trust. It works every time.
            </p> */}
            <p className="feature-section_description">
              And now it can be yours.{' '}
              <span className="feature-section_description-text">
                Say hello to Agreed!
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeatureComponentTwo;
