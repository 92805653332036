import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
const IdeasResultComponent = props => {
  const { IdeasResultImage, IdeasResultTwo } = props;
  return (
    <div className="full-container">
      <div className="ideas-section">
        <div className="row">
          <div className="col-12 ">
            <p className="ideas-section_text">
              Agreed brings people together to accelerate progress from{' '}
              <span className="ideas-section_ideas-color"> ideas </span>
              to <span className="ideas-section_result-color"> results.​</span>
            </p>
          </div>

          <div className="col-12  ">
            <div className="image">
              <GatsbyImage
                imgStyle={{ width: '100%' }}
                image={IdeasResultImage}
                className="ideas-section_image"
                alt={''}
              />
              <GatsbyImage
                imgStyle={{ width: '100%' }}
                image={IdeasResultTwo}
                className="ideas-section_image-two"
                alt={''}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IdeasResultComponent;
