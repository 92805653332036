import React from 'react';
import IdeasResultComponent from '../components/landingComponents/ideasResultComponent';
import FeatureComponentTwo from '../components/landingComponents/featureComponentTwo';
import HeaderComponentLeader from '../components/landingComponents/headerComponentLeader';
import SuccessComponent from '../components/landingComponents/successComponet';
import FormComponentLandingPageTwo from '../components/landingComponents/formComponentLandingPageTwo';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import NewFooterComponent from '../components/newFooterComponent'
import Header from '../components/headerComponent';
import ProcessComponent from '../components/landingComponents/processComponent';
import FinaceIcon from '../../static/assets/financeIcon.svg';
import HealthCare from '../../static/assets/healthCareIcon.svg';
import InsuranceIcon from '../../static/assets/insuranceIcon.svg'
import ManufactoringIcon from '../../static/assets/manufactoringIcon.svg'
import MarketingIcon from '../../static/assets/marketingIcon.svg';
import StepsComponent from '../components/landingComponents/stepsComponent';
import { Link } from 'gatsby';

const processItems = [
  {
    text: 'Finance',
    icon: <FinaceIcon />,
    description: '12K finance employees cut costs by 50%',
  },
  {
    text: 'Marketing',
    icon: <MarketingIcon />,
    description: '$400M marketing company increased earnings by 10%',
  },
  {
    text: 'Healthcare',
    icon: <HealthCare />,
    description: '$1B healthcare company improved annual earnings by $40M',
  },
  {
    text: 'Insurance',
    icon: <InsuranceIcon />,
    description: '$6.5B insurance company gained $200+M in annual profit',
  },
  {
    text: 'Manufacturing',
    icon: <ManufactoringIcon />,
    description: '500 manufacturing employees delivered 40+M in annual profit',
  },
];
const listItem = [
  {
    title: 'Innovation Leader',
    subtitle:
      'Innovation leaders are expected to push the boundaries of what’s possible, while also fostering a culture and pipeline of innovation.',
    description:
      'How do you translate all of the hype and ideation into action and outcome?',
    text: [
      <div className="steps-section-rectangle">
        <p className="steps-section-rectangle-text">
          “Agreed helps me socialize CX insights in a way that productively
          engages other teams and highlights the benefits for everyone.“
        </p>
        <div className="steps-section-rectangle-wrapper">
          <div>
            <p className="steps-section-rectangle-question">
              Ready to see how Agreed helps you win?
            </p>
            <p className="steps-section-rectangle-question-bold">
              Request a live demo today.
            </p>
          </div>
          <Link
            className="steps-section-rectangle-wrapper-button"
            to="/contact"
          >
            Contact Sales
          </Link>
        </div>
      </div>,
    ],
  },
  {
    title: 'Transformation Leader',
    subtitle:
      'Transformation leaders are tasked with high demands -- like a flight crew changing an engine mid-flight.',
    description:
      'Problems often arise when coordinating stakeholders, data, and decision makers across complex organizations.​',
    text: [
      <div className="steps-section-rectangle">
        <p className="steps-section-rectangle-text">
          “Agreed gave our executive team a huge confidence boost that our
          transformation efforts will actually work. We love the visibility and
          accountability of having stakeholders on the platform.“
        </p>
        <div className="steps-section-rectangle-wrapper">
          <div>
            <p className="steps-section-rectangle-question">
              Ready to see how Agreed helps you win?
            </p>
            <p className="steps-section-rectangle-question-bold">
              Request a live demo today.
            </p>
          </div>
          <Link
            className="steps-section-rectangle-wrapper-button"
            to="/contact"
          >
            Contact Sales
          </Link>
        </div>
      </div>,
    ],
  },
  {
    title: 'Project Management (PMO)',
    subtitle: 'PMOs are always thinking about optimizing project execution.',
    description:
      'Problems often arise when projects haven’t been fully vetted with stakeholders… putting budgets and timelines at risk.',
    text: [
      <div className="steps-section-rectangle">
        <p className="steps-section-rectangle-text">
          “Launching a project with everybody aligned at the beginning feels
          like a dream come true. Literally, Agreed is a dream come true because
          it gets everyone on board.“
        </p>
        <div className="steps-section-rectangle-wrapper">
          <div>
            <p className="steps-section-rectangle-question">
              Ready to see how Agreed helps you win?
            </p>
            <p className="steps-section-rectangle-question-bold">
              Request a live demo today.
            </p>
          </div>
          <Link
            className="steps-section-rectangle-wrapper-button"
            to="/contact"
          >
            Contact Sales
          </Link>
        </div>
      </div>,
    ],
  },
  {
    title: 'Chief of Staff',
    subtitle:
      'Most people in medium to highly complex organizations don’t understand the rigor it takes to get even the best ideas approved.',
    description:
      'Whether you are just a glorified coffee runner or running a P&L, the problem of herding cross-functional teams is the same…',
    subDescription:
      'How do you get people across the organization bought into these critical initiatives?',
    text: [
      <div className="steps-section-rectangle">
        <p className="steps-section-rectangle-text">
          “I love that Agreed gives me a little bit of peer pressure leverage
          that helps move initiatives along and highlight where (and who) the
          roadblocks are, especially when I’m dealing with senior leadership in
          every department.“
        </p>
        <div className="steps-section-rectangle-wrapper">
          <div>
            <p className="steps-section-rectangle-question">
              Ready to see how Agreed helps you win?
            </p>
            <p className="steps-section-rectangle-question-bold">
              Request a live demo today.
            </p>
          </div>
          <Link
            className="steps-section-rectangle-wrapper-button"
            to="/contact"
          >
            Contact Sales
          </Link>
        </div>
      </div>,
    ],
  },
  {
    title: 'Change Management',
    subtitle:
      'Your job is to enable a better functioning organization -- paving the way for all of the various stakeholders to weigh in and come to a successful decision.​ ',
    description:
      'How do you make sure everyone is brought along, and nobody is left out?',
    text: [
      <div className="steps-section-rectangle">
        <p className="steps-section-rectangle-text">
          “Agreed is my favorite! It’s like Agreed is my clone for socializing,
          gathering feedback, and following up with a plethora of stakeholders.“
        </p>
        <div className="steps-section-rectangle-wrapper">
          <div>
            <p className="steps-section-rectangle-question">
              Ready to see how Agreed helps you win?
            </p>
            <p className="steps-section-rectangle-question-bold">
              Request a live demo today.
            </p>
          </div>
          <Link
            className="steps-section-rectangle-wrapper-button"
            to="/contact"
          >
            Contact Sales
          </Link>
        </div>
      </div>,
    ],
  },
  {
    title: 'Strategic Planning',
    subtitle: 'Your executive team has aligned on clear strategic priorities.​',
    description:
      'How do you manage the supporting initiatives with better accountability than PowerPoint and spreadsheets?​',
    text: [
      <div className="steps-section-rectangle">
        <p className="steps-section-rectangle-text">
          “Agreed gives me a better view into the initiatives that have
          historically been on everybody’s personal desktop. New, we can
          accelerate our strategic plan with projects that we didn’t even know
          about before.“
        </p>
        <div className="steps-section-rectangle-wrapper">
          <div>
            <p className="steps-section-rectangle-question">
              Ready to see how Agreed helps you win?
            </p>
            <p className="steps-section-rectangle-question-bold">
              Request a live demo today.
            </p>
          </div>
          <Link
            className="steps-section-rectangle-wrapper-button"
            to="/contact"
          >
            Contact Sales
          </Link>
        </div>
      </div>,
    ],
  },
  {
    title: 'Customer Experience',
    subtitle:
      'You’re surfacing insights into what the customer needs in order to be more loyal to your product or brand.​',
    description:
      'The catch is getting other teams in the organization to focus on your initiatives.​',
    text: [
      <div className="steps-section-rectangle">
        <p className="steps-section-rectangle-text">
          “Agreed helps me socialize CX insights in a way that productively
          engages other teams and highlights the benefits for everyone.“
        </p>
        <div className="steps-section-rectangle-wrapper">
          <div>
            <p className="steps-section-rectangle-question">
              Ready to see how Agreed helps you win?
            </p>
            <p className="steps-section-rectangle-question-bold">
              Request a live demo today.
            </p>
          </div>
          <Link
            className="steps-section-rectangle-wrapper-button"
            to="/contact"
          >
            Contact Sales
          </Link>
        </div>
      </div>,
    ],
  },
];

const LeaderPage = props => {
  const {
    data: {
      wpPage: {
        seo,
        landingPageLeadSection: { leadButton, leadSubtitle, leadTitle },

        landingPageIdeasSection: {
          championDescriptionCase,
          championDescriptionChanges,
          championDescriptionThings,
          championIdeaTitle,
          championImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: championImage }],
            },
          },
        },
        offerBrakePointSection: {
          landingBrakePoint,
          landingBrakePointSubtitle,
          // pairwiesLogo: {
          //   localFile: {
          //     childrenImageSharp: [{ gatsbyImageData: pairwiesLogo }],
          //   },
          // },
          bankOfAmericaImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: bankAmericaImageOf }],
            },
          },
          guthyImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: guthyImage }],
            },
          },
          humanaImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: humanaImage }],
            },
          },
          mellonImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: mellonImage }],
            },
          },
          mrvImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: mrvImage }],
            },
          },
          mufgImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: mufgImage }],
            },
          },
          nyseImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: nyseImage }],
            },
          },
          // panasonicImage: {
          //   localFile: {
          //     childrenImageSharp: [{ gatsbyImageData: panasonicImage }],
          //   },
          // },
          // pncImage: {
          //   localFile: {
          //     childrenImageSharp: [{ gatsbyImageData: pncImage }],
          //   },
          // },
        },
        landingPageGetFreeAccountSection: {
          landingFreeAccountTitle,
        },
        landingPageIdeasAndResultSection: {
          ideasResultImageMobile: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: ideasResultImageMobile }],
            },
          },
          ideasResultsImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: ideasResultsImage }],
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
        <Layout title={title} seo={seo}>
          <Header />
          <HeaderComponentLeader
            teamworkTitle={leadTitle}
            teamworkDescription={leadSubtitle}
            teamworkButtonText={leadButton}
          />
          <FeatureComponentTwo
            featureTitle={championIdeaTitle}
            featureWorkDescription={championDescriptionThings}
            featureChangeDescription={championDescriptionChanges}
            featureBusinessDescription={championDescriptionCase}
            FeatureImage={championImage}
          />
          <IdeasResultComponent
            IdeasResultImage={ideasResultsImage}
            IdeasResultTwo={ideasResultImageMobile}
          />
          <StepsComponent
            listItem={listItem}
            isChangeLeader={true}
          />
          <SuccessComponent
            bankOfAmericaImage={bankAmericaImageOf}
            guthyImage={guthyImage}
            humanImage={humanaImage}
            mellonImage={mellonImage}
            MRVImage={mrvImage}
            mufgImage={mufgImage}
            nyseImage={nyseImage}
            sucessTitle={landingBrakePoint}
            sucessSubtitle={landingBrakePointSubtitle}
          />
          <ProcessComponent processItems={processItems} />
          <FormComponentLandingPageTwo
            freeAccountTitle={landingFreeAccountTitle}
            freeAccountButton="Get Started"
            isSolutions={true}
          />
          <NewFooterComponent isProduct={true} />
        </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(
      slug: {
        eq: "add-value-to-your-organisation-improve-your-business-processes"
      }
    ) {
      seo {
        metaDesc
        title
      }
      landingPageLeadSection {
        leadButton
        leadSubtitle
        leadTitle
      }
      landingPageIdeasSection {
        championDescriptionCase
        championDescriptionChanges
        championDescriptionThings
        championIdeaTitle
        championImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      offerBrakePointSection {
        landingBrakePoint
        landingBrakePointSubtitle
        pairwiesLogo {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        bankOfAmericaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        guthyImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        humanaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mellonImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mrvImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mufgImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        nyseImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        panasonicImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        pncImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
      }
      landingPageGetFreeAccountSection {
        landingFreeAccountTitle
      }
      landingPageReviewsSection {
        insuranceDescription
        insuranceTitle
        landingReviewGreg
        landingReviewsErin
        landingReviewsSteffen
        landingReviewsTitle
        manufacturingDescription
        manufacturingText
        reviewsNameSliderOne
        reviewsNameSliderThree
        reviewsNameSliderTwo
      }
      landingPageIdeasAndResultSection {
        ideasResultImageMobile {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        ideasResultsImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      landingPageExplanationSection {
        landingButtonText
        landingGoodIdeaDescription
        landingDeliverIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingApprovalIdeaIamge {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingGoodIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingGoodIdeaTitle
        landingInProgressIdeaDescription
        landingInProgressIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingInProgressIdeaTitle
        landingRealiseIdeaDescription
        landingRealiseIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingRealiseIdeaTitle
        landingRightIdeaDescription
        landingRightIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingRightIdeaTitle
        landingThinkIdeDescription
        landingThinkIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingThinkIdeaTitle
        landingApprovalIdea
        landingApprovalIdeaDescription
        landingDeliverIdea
        landingDeliverIdeaDescription
      }
      stepsSectionChangeMaker {
        buildtrustImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        deliverroiwithcertaintyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        getairtimeIamge {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        gettractionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        streamlineapprovalImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        winbuyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        customerexperienceImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default LeaderPage;
