import React,{useState} from "react";

const ProcessComponent = (props) =>{
      const [isActive, setisActive] = useState(2);
      const {processItems} = props;

    return (
      <div className="full-container">
        <div className="process-section">
          <h5 className="process-section-title">
            Our proven process delivers 90-110% of the approved value.
          </h5>
          <div className="process-section-wrapper">
            <ul className="process-section-list">
              {processItems.map((item, index) => {
                return (
                  <li key={index}>
                    <button
                      aria-label="lists"
                      onClick={() => {
                        setisActive(index);
                      }}
                      className={
                        index === isActive
                          ? 'process-section-list-button-active'
                          : 'process-section-list-button'
                      }
                    >
                      <span
                        className={
                          index === isActive
                            ? 'process-section-list-icon-active'
                            : 'process-section-list-icon'
                        }
                      >
                        {item.icon}
                      </span>
                      <span
                        className={
                          index === isActive
                            ? 'process-section-list-text-active'
                            : 'process-section-list-text'
                        }
                      >
                        {item.text}
                      </span>
                    </button>
                    {index === isActive && (
                      <span className="process-section-subtext">
                        <span className="process-section-description">
                          {item.description}
                        </span>
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="process-section-text">
            {isActive === 0 && (
              <p className="process-section-description">
                12K finance employees cut costs by 50%
              </p>
            )}
            {isActive === 1 && (
              <p className="process-section-description">
                $400M marketing company increased earnings by 10%
              </p>
            )}
            {isActive === 2 && (
              <p className="process-section-description">
                $1B healthcare company improved annual earnings by $40M
              </p>
            )}
            {isActive === 3 && (
              <p className="process-section-description">
                $6.5B insurance company gained $200+M in annual profit
              </p>
            )}
            {isActive === 4 && (
              <p className="process-section-description">
                500 manufacturing employees delivered 40+M in annual profit
              </p>
            )}
          </div>
        </div>
      </div>
    );
}
export default ProcessComponent